/** @jsx jsx */
import { jsx } from "theme-ui"
// import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404 - Not found" />
    <div sx={{ variant: "styles.content" }}>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </Layout>
)

export default NotFoundPage
